import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import CardBlogPost from "../components/card-blog-post";

const Utilities = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title;
  const posts = data.allMdx.edges;

  return (
    <Layout location={data.location} title={siteTitle} lang="en">
      <SEO title="All posts" />
      <div class="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug;
          const tags = node.frontmatter.tag || "Dynamics CRM";
          const excerpt = node.frontmatter.description || node.excerpt;
          const href = `/utilities${node.fields.slug}`;
          const date = node.frontmatter.date;
          const min = node.frontmatter.minutes || null;
          return (
            <CardBlogPost
              title={title}
              tags={tags}
              excerpt={excerpt}
              href={href}
              date={date}
              min={min}
            />
          );
        })}
      </div>
    </Layout>
  );
};

export default Utilities;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      filter: { frontmatter: { parenturl: { eq: "utilities" } } }
      sort: { fields: [frontmatter___title], order: ASC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            description
            tag
            minutes
          }
        }
      }
    }
  }
`;
