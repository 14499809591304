import { Link } from "gatsby";
import React from "react";

const CardBlogPost = props => {
  const { title, tags, excerpt, href, date, min, image, callback } = props;
  return (
    <div className="flex-1 bg-white p-6 flex flex-col justify-between rounded-lg text-justify">
      <div className="flex-shrink-0">
        <img className="h-48 w-full object-cover" src={image} alt="" />
      </div>
      <div className="relative max-w-lg divide-y-2 divide-gray-200 lg:max-w-7xl text-justify">
        <div className="mt-2 grid gap-16 pt-2 lg:gap-x-5 lg:gap-y-12">
          <div>
            <div>
              {tags.split(";").map(tag => {
                const tagTrimmed = tag.trim();
                return (
                  <span
                    key={tagTrimmed}
                    className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-indigo-100 text-indigo-800 cursor-pointer	"
                    onClick={() => callback(tagTrimmed)}
                  >
                    {tagTrimmed}
                  </span>
                );
              })}
            </div>
            <Link to={href} className="block mt-4">
              <p className="text-xl font-semibold text-gray-900 text-justify">
                {title}
              </p>
              <p className="mt-3 text-base text-gray-500 text-justify">
                {excerpt}
              </p>
            </Link>
            <div className="mt-6 flex items-center text-justify">
              <div className="ml-3">
                <div className="flex space-x-1 text-sm text-gray-500">
                  <time dateTime={date}>{date}</time>
                  <span aria-hidden="true">&middot;</span>
                  {min != null ? <span>{min} min read</span> : <></>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardBlogPost;
